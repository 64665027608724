import React from "react";
import "./ComingSoon.css";

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <img className="logo" src="images/favicon.png" alt="Logo" height="100vh"></img>
      <div className="content">
        <h3>Inspired by Brazil</h3>
        <h1 className="gradient-text">Coming Soon</h1>
        <h3>Loved by Everyone</h3>
      </div>
      <div className="info-container">
        <h3>What's Coming?</h3>
        <p>Fresh pastel dough, delivered to your door effortlessly!</p>
        <p>Catch us live at your favourite local markets and events!</p>
        <p>Unlock interactive features and explore exciting surprises.</p>
        <p>...and so much more to explore!</p>
      </div>
      <footer>
        <div className="social-links">
          <a
            href="https://www.facebook.com/goodfillingsnz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="images/facebook-logo-thin-svgrepo-com.png"
              alt="Facebook"
              width="25"
              height="auto"
            />
          </a>
          <a
            href="https://www.instagram.com/goodfillingsnz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="images/instagram-logo-thin-svgrepo-com.png"
              alt="Instagram"
              width="25"
              height="auto"
            />
          </a>
          <a
            href="https://www.tiktok.com/@goodfillingsnz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="images/tiktok-logo-thin-svgrepo-com.png"
              alt="Tiktok"
              width="25"
              height="auto"
            />
          </a>
          <a
            href="https://wa.me/64274709045"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="images/whatsapp-logo-thin-svgrepo-com.png"
              alt="Whatsapp"
              width="25"
              height="auto"
            />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default ComingSoon;
