import "./App.css"; // Retain this for global styles
import ComingSoon from "./ComingSoon"; // Import the new Coming Soon component

function App() {
  return (
    <div className="App">
      <ComingSoon />
    </div>
  );
}

export default App;
